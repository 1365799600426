<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'

  /**
   * A card representing a cart item, including the main image and information about the variant such as its brand, title, price, etc.
   */
  export default {
    props: {
      /** Data about the line item { variant, quantity, removed } */
      lineItem: {
        type: Object,
        required: true,
      },
      /** Max quantity for this line item */
      maxQuantity: {
        type: Number,
        default: 10,
      },
    },
    data() {
      return {
        errorMessage: null,
        timeout: undefined,
      }
    },
    computed: {
      ...get('marketplaceCart', ['variantCanAddRushShipping']),
      screen,
      lineItemQuantity: {
        get() {
          return this.lineItem.quantity
        },
        set(quantity) {
          this.$emit('updateQuantity', { variantId: this.variant.id, quantity })
        },
      },
      variant() {
        return this.lineItem.variant
      },
      removed() {
        return this.lineItem.removed
      },
      retailValue() {
        return this.variant.msrp || this.variant.retailValue
      },
      discountAmount() {
        return this.retailValue - this.variant.price
      },
      percentOff() {
        return Math.round((this.discountAmount / this.retailValue) * 100)
      },
    },
    methods: {
      removeCartItem() {
        this.$emit('removeCartItem', { variantId: this.variant.id })
      },
      toggleRemoveItem() {
        this.$emit('toggleRemoveItem', { variantId: this.variant.id })
      },
      openTrendingQuestionsModal(update) {
        this.$emit('openTrendingQuestionsModal', update)
      },
    },
  }
</script>

<template>
  <!--------------- Item removed --------------->
  <div v-if="removed" class="py-4">
    <!-- TODO: `grid` and `flex` shouldn't be placed on the same element -->
    <div
      class="grid grid-cols-cart-item-mobile lg:grid-cols-cart-item-desktop bg-dawn p-3 flex items-center"
    >
      <div class="w-12">
        <BaseImage
          :src="variant.previewCardImages[0]"
          :alt="variant.title"
          :aspect-ratio="1"
          responsive="xs"
        />
      </div>
      <div class="flex items-center justify-between">
        <div class="text-sm">
          Item Removed.
          <BaseLinkStyled inline @click="toggleRemoveItem">Undo</BaseLinkStyled>
        </div>
        <div>
          <BaseIconClickable
            label="Dismiss"
            :size="3"
            :padding="2"
            color="black"
            @click="removeCartItem"
          >
            <IconX />
          </BaseIconClickable>
        </div>
      </div>
    </div>
  </div>
  <!--------------- Cart item --------------->
  <div
    v-else
    class="py-4 grid grid-cols-cart-item-mobile lg:grid-cols-cart-item-desktop gap-x-3 2xs:gap-x-4 lg:gap-x-6"
    data-cy="cart-item"
  >
    <div class="flex flex-col items-center">
      <!-- Product image that links to PDP on click  -->
      <BaseLink class="w-full" @click="$emit('viewDetails', variant)">
        <BaseImage
          :src="variant.previewCardImages[0]"
          :alt="variant.title"
          :aspect-ratio="1"
          responsive="xs"
        />
        <BaseTag
          v-if="percentOff > 0"
          :label="`${percentOff}% off`"
          color="primary"
          class="absolute top-0 left-0"
        />
      </BaseLink>
      <!-- Mobile quantity selector  -->
      <BaseQuantitySelect
        v-if="!screen.lg"
        v-model.number="lineItemQuantity"
        breakpoint="lg"
        :max="maxQuantity"
        class="mt-3"
      />
    </div>
    <div class="flex justify-between">
      <div class="lg:py-10px flex flex-col justify-between w-full">
        <!-- Brand and title  -->
        <div>
          <span class="text-sm uppercase truncate leading-close">{{ variant.brand }}</span>
          <span
            data-cy="cart-item-title"
            class="text-sm overflow-hidden ellipsis line-clamp-2 lg:mt-1 mb-2"
            style="line-height: 1.2"
          >
            {{ variant.title }}
          </span>
          <!-- Mobile rush/standard delivery links -->
          <template v-if="!screen.lg">
            <span
              v-if="variantCanAddRushShipping(variant)"
              class="bg-dawn-lt2"
              style="padding: 2px 6px 4px"
            >
              <BaseIcon :size="5" class="inline-block align-middle mt-3px">
                <IconRushShipping />
              </BaseIcon>
              <BaseLinkStyled
                font-size="xs"
                color="black"
                @click="openTrendingQuestionsModal({ type: 'trending', slug: 'holiday-delivery' })"
              >
                <span>Holiday Delivery Eligible</span>
              </BaseLinkStyled>
            </span>
            <div v-else>
              <BaseLinkStyled font-size="xs" color="black" @click="$emit('openShippingModal')">
                <span>Standard Delivery</span>
              </BaseLinkStyled>
            </div>
          </template>
        </div>
        <!-- Mobile price breakdown  -->
        <div v-if="!screen.lg">
          <div class="flex justify-between">
            <div>
              <span class="font-semibold mr-2">{{ $formatPrice(variant.price) }}</span>
              <s>{{ $formatPrice(retailValue) }}</s>
            </div>
            <span v-if="discountAmount > 0" class="hidden lg:inline">You </span>Save:
            {{ $formatPrice(discountAmount) }}
          </div>
        </div>
        <!-- Desktop quantity selector  -->
        <div v-if="screen.lg" class="flex justify-between">
          <div class="flex items-center">
            <BaseQuantitySelect
              v-model.number="lineItemQuantity"
              breakpoint="lg"
              :max="maxQuantity"
            />
            <div class="ml-5 pl-5 border-l border-black border-opacity-20">
              <BaseLinkStyled color="black" font-size="sm" @click="toggleRemoveItem">
                Remove
              </BaseLinkStyled>
            </div>
          </div>
        </div>
      </div>
      <!-- Desktop price breakdown  -->
      <div
        v-if="screen.lg"
        class="min-w-50 py-10px flex flex-col justify-between text-right leading-close"
      >
        <div>
          <div>
            <s>{{ $formatPrice(retailValue) }}</s>
            <span class="font-semibold ml-2">{{ $formatPrice(variant.price) }}</span>
          </div>
          <div>You Save: {{ $formatPrice(discountAmount) }}</div>
        </div>
        <!-- Desktop rush/standard delivery links -->
        <div v-if="variantCanAddRushShipping(variant)">
          <BaseIcon :size="5" class="inline-block align-middle mt-3px">
            <IconRushShipping />
          </BaseIcon>
          <BaseLinkStyled
            font-size="xs"
            color="black"
            @click="openTrendingQuestionsModal({ type: 'trending', slug: 'holiday-delivery' })"
          >
            <span>Holiday Delivery Eligible</span>
          </BaseLinkStyled>
        </div>
        <div v-else>
          <BaseLinkStyled font-size="xs" color="black" @click="$emit('openShippingModal')">
            <span>Standard Delivery</span>
          </BaseLinkStyled>
        </div>
      </div>
    </div>
    <!-- Cart Item error -->
    <div v-if="errorMessage" class="my-3 col-span-3">
      <BasePanelWarning dismissible :icon-size="5" @dismiss="errorMessage = null">
        <div class="text-sm"
          >Something went wrong while updating your cart. Please refresh the page and try
          again.</div
        >
      </BasePanelWarning>
    </div>
  </div>
</template>
