<script>
  /**
   * A layout component that arranges `CartItem` components into a list.
   */
  import CartItem from '@/components/checkout/CartItem'

  export default {
    components: {
      CartItem,
    },
    props: {
      lineItems: {
        type: Array,
        default() {
          return []
        },
      },
    },
    methods: {
      maxQuantity(variant) {
        return Math.min(variant.stock, variant.maxQuantity)
      },
    },
  }
</script>

<template>
  <div class="divide-y divide-gray-active">
    <slot>
      <CartItem
        v-for="(lineItem, index) in lineItems"
        :key="index"
        :line-item="lineItem"
        :max-quantity="maxQuantity(lineItem.variant)"
        @toggleRemoveItem="$emit('toggleRemoveItem', $event)"
        @removeCartItem="$emit('removeCartItem', $event)"
        @updateQuantity="$emit('updateQuantity', $event)"
        @openTrendingQuestionsModal="$emit('openTrendingQuestionsModal', $event)"
        @openShippingModal="$emit('openShippingModal')"
        @viewDetails="$emit('viewDetails', $event)"
      />
    </slot>
  </div>
</template>
