<script>
  import { get, call } from 'vuex-pathify'
  import createGuardMixin from '@/helpers/createGuardMixin'
  import CartItemList from '@/components/checkout/CartItemList'
  import SoldOutItems from '@/components/checkout/SoldOutItems'
  import ProductSlider from '@/components/marketplace/ProductSlider'
  import OrderSummary from '@/components/checkout/summary/OrderSummary'
  import CheckoutErrorModal from '@/components/checkout/CheckoutErrorModal'
  import RushShipping from '@/components/checkout/RushShipping'
  import HomeWidgetSingleEntryModal from '@/components/home/HomeWidgetSingleEntryModal'
  import ShippingModal from '@/components/marketplace/ShippingModal'
  import screen from '@/helpers/screen'

  const guardMixin = createGuardMixin(
    async ({ store, redirect }) => {
      if ((await store.dispatch('account/loggedInAsync')) === false) {
        return redirect({ name: 'AccountLogin' })
      }
      await store.dispatch('products/ensureFresh')
    },
    ['loggedIn']
  )

  export default {
    components: {
      CartItemList,
      SoldOutItems,
      ProductSlider,
      OrderSummary,
      CheckoutErrorModal,
      RushShipping,
      HomeWidgetSingleEntryModal,
      ShippingModal,
    },
    mixins: [guardMixin],
    data() {
      return {
        soldOutDetailsRef: null,
        trendingQuestionsEntry: {},
        trendingQuestionsModalVisible: false,
        showRushShippingOptInModal: false,
        openShippingModal: false,
      }
    },
    computed: {
      ...get('marketplace', ['topTenProducts']),
      ...get('products', ['products']),
      ...get('account', ['loggedIn', 'minTotalForFreeShipping', 'addresses']),
      ...get('feature', ['isRushShippingEnabled']),
      ...get('marketplaceCart', [
        'calculatingLineItems',
        'cartPreviewLoaded',
        'cartQuantity',
        'cartSavings',
        'errors',
        'hasFreeShipping',
        'isCartEmpty',
        'mobile',
        'nonSoldOutLineItems',
        'remainingAmountForFreeShipping',
        'remainingForFreeShippingCalculated',
        'soldOutLineItems',
        'storeType',
        'subtotal',
        'cartCanAddRushShipping',
        'rushShipping',
        'rushShippingPrice',
      ]),
      screen,
      sliderHeader() {
        return this.hasFreeShipping
          ? 'Recommended Products'
          : 'Add one of these to get Free Shipping!'
      },
      cartCanAddRushShipping() {
        return this.$store.get('marketplaceCart/cartCanAddRushShipping')
      },
      rushShipping: {
        get: function () {
          return this.$store.get('marketplaceCart/rushShipping')
        },
        set: function (value) {
          return this.$store.set('marketplaceCart/rushShipping', value)
        },
      },
      soldOutNotificationVisible() {
        return this.nonSoldOutLineItems.length && this.soldOutLineItems.length
      },
      trendingQuestionsEntryExists() {
        return !!Object.keys(this.trendingQuestionsEntry).length
      },
      soldOutVariants() {
        return this.soldOutLineItems.map(({ product }) => product.defaultVariant)
      },
      /* getting first 10 products that are not sold out && price is
      remainingAmountForFreeShipping + $5 (base shipping cost) && not already in cart */
      recommendedProducts() {
        return this.products
          .filter(
            ({ defaultVariant: { hasUnlimitedStock, stock, price }, id }) =>
              (hasUnlimitedStock || stock > 0) &&
              price >= this.remainingAmountForFreeShipping + 5 &&
              !this.nonSoldOutLineItems.some(({ productId }) => productId === id)
          )
          .sort((a, b) => (a.defaultVariant.price > b.defaultVariant.price ? 1 : -1))
          .slice(0, 10)
      },
    },
    watch: {
      cartQuantity() {
        if (this.cartQuantity) {
          this.reviewOrder()
        }
      },
      // if rush shipping items are removed from cart, uncheck checkbox
      cartCanAddRushShipping() {
        if (!this.cartCanAddRushShipping) {
          this.$store.set('marketplaceCart/rushShipping', false)
        }
      },
    },
    created() {
      // added here so slider products don't change if something is added from the slider
      this.clearRemovedLineItems()
      this.initCart()
    },
    methods: {
      ...call('marketplace', ['setFavorited']),
      ...call('marketplaceCart', [
        'clearErrors',
        'initCart',
        'removeCartItem',
        'toggleRemoveItem',
        'updateQuantity',
        'addToCart',
        'addToWaitlist',
        'clearRemovedLineItems',
        'reviewOrder',
        'clearSoldOutItemsFromCart',
      ]),
      scrollToSoldOutDetails() {
        this.soldOutDetailsRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      },
      openTrendingQuestionsModal($event) {
        this.trendingQuestionsEntry = $event
        this.trendingQuestionsModalVisible = true
      },
      checkForRushShipping() {
        if (this.cartCanAddRushShipping && !this.rushShipping) {
          this.showRushShippingOptInModal = true
        } else {
          this.navigateToCheckout()
        }
      },
      navigateToCheckout() {
        if (this.showRushShippingOptInModal) {
          this.showRushShippingOptInModal = false
        }
        this.$navigate({
          name: this.addresses.length > 1 ? 'CheckoutShipping' : 'CheckoutBilling',
          params: {
            storeType: this.storeType,
          },
        })
      },
      optInToRushShipping() {
        this.$store.set('marketplaceCart/rushShipping', true)
        this.navigateToCheckout()
      },
      viewDetails(variant) {
        this.$navigate({
          name: 'CartMarketProduct',
          params: {
            productId: variant.productId,
            productSlug: variant.slug,
            productTitle: variant.title,
          },
        })
      },
    },
  }
</script>

<template>
  <div class="pb-10 md:py-10 md:px-9 overflow-x-hidden">
    <Portal to="modal">
      <CheckoutErrorModal
        v-if="errors"
        :errors="errors"
        store-type="marketplace"
        @clearErrors="clearErrors"
        @placeOrder="navigateToCheckout"
      />
    </Portal>
    <!---------------------- Start mobile view ---------------------->
    <template v-if="mobile">
      <!----------- Shipping progress bar ----------->
      <div class="px-6 pt-4 pb-3 bg-dawn">
        <BaseProgressBar
          v-if="remainingForFreeShippingCalculated && minTotalForFreeShipping"
          :value="subtotal"
          :target-value="minTotalForFreeShipping"
          text-alignment="center"
        >
          <template v-slot:icon>
            <IconFreeShipping v-if="remainingAmountForFreeShipping <= 0" />
            <IconShipping v-else />
          </template>
          <span v-if="remainingAmountForFreeShipping <= 0">Congrats! You get free shipping.</span>
          <span v-else>
            {{ $formatPrice(remainingAmountForFreeShipping) }} away from FREE SHIPPING
          </span>
          <template v-slot:sublabel>
            <span v-if="remainingAmountForFreeShipping > 0">
              Free Shipping on orders over {{ $formatPrice(minTotalForFreeShipping) }}.
              <BaseLinkStyled :to="{ name: 'Market' }">Shop now</BaseLinkStyled>
            </span>
            <span v-else> We automatically applied it to your order. </span>
          </template>
        </BaseProgressBar>
      </div>
      <!----------- Mobile order summary and checkout button ----------->
      <div v-if="!isCartEmpty" class="px-6 pt-4 bg-dawn">
        <div class="flex justify-between">
          <div>
            <p>
              <span class="font-medium">Subtotal:</span>
              <span>
                <span v-if="calculatingLineItems">Calculating... </span>
                <span v-else class="font-semibold" data-cy="cart-subtotal">
                  {{ $formatPrice(subtotal, false) }}
                </span>
              </span>
            </p>
            <p class="text-gray-dark text-sm">
              ({{ cartQuantity }} {{ cartQuantity > 1 ? 'items' : 'item' }})
            </p>
          </div>
          <div class="text-sm font-medium">Savings: {{ $formatPrice(cartSavings) }}</div>
        </div>
      </div>
      <div v-if="!isCartEmpty" class="px-6 py-3 bg-dawn">
        <BaseButton :disabled="calculatingLineItems || isCartEmpty" @click="checkForRushShipping">
          Continue to checkout
        </BaseButton>
      </div>
      <!----------- Empty cart message ----------->
      <div v-else class="py-7 bg-dawn flex flex-col flex-wrap content-center items-center">
        <BaseHeading size="h2-lg" tag="h2"> Your cart is empty. </BaseHeading>
        <div class="pt-6 px-4 w-full">
          <BaseButton :to="{ name: 'Market' }"> Shop Products&nbsp; </BaseButton>
        </div>
      </div>
      <!----------- Loading spinner ----------->
      <BaseSpinner v-if="calculatingLineItems" overlay="fixed" overlay-color="dawn" class="z-10" />
    </template>
    <!---------------------- End mobile view ---------------------->

    <div class="max-w-320 mx-auto">
      <div class="layout md:mt-8">
        <div>
          <!---------------------- Start desktop header ---------------------->
          <div v-if="!mobile" class="flex items-center mb-4">
            <div class="mr-3 w-11 flex-shrink-0">
              <BaseIcon>
                <IconBoxIllustration />
              </BaseIcon>
            </div>
            <div class="flex-1 flex justify-between items-baseline">
              <div class="font-body">
                <BaseHeading size="h5" tag="h1">Your Items</BaseHeading>
              </div>
              <BaseLinkStyled
                :to="{ name: 'Market' }"
                color="black"
                font-size="xs"
                icon-position="left"
                :icon-size="3"
                class="text-right"
              >
                <template v-slot:icon>
                  <IconArrowLeft />
                </template>
                <template v-slot>Back to shopping</template>
              </BaseLinkStyled>
            </div>
          </div>
          <!---------------------- End desktop header ---------------------->
          <!----------- Rush shipping ----------->
          <div
            v-if="cartCanAddRushShipping"
            :class="`${screen.md ? 'bg-primary' : 'bg-dawn-lt3'} px-6 pt-1 pb-4 md:mt-6 md:p-6`"
          >
            <RushShipping
              cart="marketplaceCart"
              :color="screen.md ? 'dawn' : 'primary'"
              :icon-size="7"
              class="flex-col lg:flex-row border-t border-b border-black md:border-0 md:py-0"
            >
              <span class="mt-2px leading-close">
                Holiday Delivery - {{ $formatPrice(rushShippingPrice) }}
              </span>
              <template v-slot:sublabel>
                <div
                  :class="`text-2xs text-center leading-close ${
                    screen.md ? 'text-dawn' : ''
                  }  lg:text-right mt-4 lg:mt-0`"
                >
                  Add Holiday Delivery to get eligible items <br />
                  by 12/25.
                  <BaseLinkStyled
                    :color="screen.md ? 'dawn' : 'black'"
                    @click="
                      openTrendingQuestionsModal({ type: 'trending', slug: 'holiday-delivery' })
                    "
                  >
                    See Details
                  </BaseLinkStyled>
                </div>
              </template>
            </RushShipping>
          </div>
          <!----------- Sold out notification ----------->
          <div
            v-if="soldOutNotificationVisible"
            class="mt-2 flex justify-center md:justify-start pb-4 border-b border-gray-active"
          >
            <div
              class="flex items-center pl-3 pr-12 py-2 border border-gray-active text-sm leading-tight"
            >
              <div class="mr-2 flex-shrink-0 text-green">
                <BaseIcon :size="6">
                  <IconInfo />
                </BaseIcon>
              </div>
              <div>
                {{ soldOutLineItems.length }} sold out item{{
                  soldOutLineItems.length > 1 ? 's' : ''
                }}.
              </div>
              <div class="ml-2 flex-shrink-0">
                <BaseLinkStyled @click="scrollToSoldOutDetails">View</BaseLinkStyled>
              </div>
              <div class="w-8" style="flex-shrink: 99999" />
              <div class="absolute right-0 mr-2 inset-y-0 flex items-center">
                <BaseIconClickable
                  label="Dismiss"
                  :size="3"
                  :padding="2"
                  @click="clearSoldOutItemsFromCart"
                >
                  <IconX />
                </BaseIconClickable>
              </div>
            </div>
          </div>
          <div class="-mx-3 md:mx-0">
            <!----------- Empty cart message ----------->
            <div
              v-if="isCartEmpty && !mobile"
              class="mt-4 py-7 bg-dawn flex flex-col flex-wrap content-center items-center"
            >
              <BaseHeading size="h2-lg" tag="h2"> Your cart is empty. </BaseHeading>
              <div class="pt-6 px-4 w-80">
                <BaseButton :to="{ name: 'Market' }"> Shop Products&nbsp; </BaseButton>
              </div>
            </div>
            <!----------- Cart items ----------->
            <CartItemList
              :line-items="nonSoldOutLineItems"
              class="px-6 md:px-0"
              @toggleRemoveItem="toggleRemoveItem"
              @removeCartItem="removeCartItem"
              @updateQuantity="updateQuantity"
              @openTrendingQuestionsModal="openTrendingQuestionsModal"
              @openShippingModal="openShippingModal = true"
              @viewDetails="viewDetails($event)"
            />
          </div>
          <!----------- Sold out cart items ----------->
          <div
            v-if="soldOutLineItems.length > 0"
            v-ref="soldOutDetailsRef"
            :class="`${nonSoldOutLineItems.length > 0 ? 'border-t border-gray-active' : ''}`"
          >
            <div class="my-4 flex px-2 text-xs leading-snug">
              <div class="mr-2 flex-shrink-0 text-green">
                <BaseIcon :size="6">
                  <IconInfo />
                </BaseIcon>
              </div>
              <div>
                Sold out items have been removed from your order.<br />
                Add to waitlist and we’ll notify once available.
              </div>
            </div>
            <SoldOutItems type="marketplace" :variants="soldOutVariants" />
          </div>
        </div>
        <!---------------------- Desktop view ---------------------->
        <template v-if="!mobile">
          <div />
          <div>
            <div class="p-4 rounded-sm bg-dawn">
              <BaseProgressBar
                v-if="remainingForFreeShippingCalculated"
                :value="subtotal"
                :target-value="minTotalForFreeShipping"
              >
                <template v-slot:icon>
                  <IconFreeShipping v-if="remainingAmountForFreeShipping <= 0" />
                  <IconShipping v-else />
                </template>
                <span v-if="remainingAmountForFreeShipping <= 0">You get Free Shipping!</span>
                <span v-else>
                  {{ $formatPrice(remainingAmountForFreeShipping) }} away from FREE SHIPPING
                </span>
                <template v-slot:sublabel>
                  <span v-if="remainingAmountForFreeShipping > 0">
                    Free Shipping on orders over {{ $formatPrice(minTotalForFreeShipping) }}.
                    <BaseLinkStyled :to="{ name: 'Market' }">Shop now</BaseLinkStyled>
                  </span>
                  <span v-else> We automatically applied it to your order. </span>
                </template>
              </BaseProgressBar>
              <BaseDivider v-if="remainingForFreeShippingCalculated" class="my-5" />
              <OrderSummary v-if="!isCartEmpty" type="marketplace" view="cart" />
              <!-- Continue to checkout button -->
              <BaseButton
                :disabled="calculatingLineItems || isCartEmpty"
                @click="checkForRushShipping"
              >
                Continue to checkout
              </BaseButton>
              <!----------- Rush shipping (desktop order summary) ----------->
              <RushShipping
                v-if="cartCanAddRushShipping"
                cart="marketplaceCart"
                class="mt-4 border-t border-b border-black"
              >
                <span class="text-xs mt-2px"> Holiday Delivery Fee </span>
                <BaseIcon
                  type="button"
                  label="learn more"
                  :size="3"
                  class="ml-1 mt-1px inline-block align-middle"
                  @click="
                    openTrendingQuestionsModal({ type: 'trending', slug: 'holiday-delivery' })
                  "
                >
                  <IconInfoAlertOutline />
                </BaseIcon>
                <template v-slot:sublabel>
                  <span class="text-xs"> {{ $formatPrice(rushShippingPrice, false) }} </span>
                </template>
              </RushShipping>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-if="isCartEmpty" class="my-8" />
    <BaseDivider v-else class="my-8" />
    <!----------- Recommended products slider ----------->
    <div class="overflow-hidden mx-auto max-w-330 px-6 md:px-4 2xl:px-0">
      <ProductSlider
        v-if="remainingForFreeShippingCalculated"
        :products="recommendedProducts"
        has-add-to-cart-button
        class="max-w-320 mx-auto"
        @addToCart="addToCart"
        @addToWaitlist="addToWaitlist"
        @setFavorited="setFavorited"
      >
        <template v-slot:heading>{{ sliderHeader }}</template>
      </ProductSlider>
    </div>

    <!-- Shipping Modals -->
    <HomeWidgetSingleEntryModal
      v-if="trendingQuestionsEntryExists"
      :open="trendingQuestionsModalVisible"
      :update="trendingQuestionsEntry"
      override-date="<span class='font-medium'>SHIPPING &amp; DELIVERY</span>"
      @dismiss="trendingQuestionsModalVisible = false"
    />

    <Portal to="productModal">
      <RouterView />
    </Portal>

    <Portal to="modal">
      <BaseModal
        size="xs-sm"
        :open="showRushShippingOptInModal"
        bg-color="dawn"
        class="text-center"
      >
        <div class="flex items-center justify-center">
          <BaseIcon :size="screen.md ? 13 : 10" class="mt-5px mr-2">
            <IconRushShipping />
          </BaseIcon>
          <span class="text-4xl md:text-38px font-heading font-medium">Holiday Delivery</span>
        </div>
        <p class="mt-2 text-sm md:text-md md:px-6">
          Some items in your cart are eligibile for Holiday Delivery. Would you like to opt in to
          Holiday Delivery for $10 and receive eligible products by 12/25?
          <BaseLinkStyled
            font-weight="medium"
            @click="openTrendingQuestionsModal({ type: 'trending', slug: 'holiday-delivery' })"
          >
            Learn how it works.
          </BaseLinkStyled>
        </p>
        <BaseButton class="my-6 max-w-72 mx-auto" @click="optInToRushShipping">
          <div class="text-lg">
            Add Holiday Delivery
            <span> &#8729; </span>
            $10
          </div>
        </BaseButton>
        <BaseLinkStyled color="black" @click="navigateToCheckout">
          No thanks, continue to checkout
        </BaseLinkStyled>
      </BaseModal>
    </Portal>
    <ShippingModal :open="openShippingModal" type="seasonal" @dismiss="openShippingModal = false" />
  </div>
</template>

<style lang="postcss" scoped>
  .layout {
    @apply grid;

    @screen md {
      grid-template-columns: 628fr 76fr theme('spacing.82');
    }
  }
</style>
